import {
  useQuery,
  UseQueryOptions,
  useMutation,
  UseMutationOptions,
} from 'react-query'

import { createFetcher } from '../api'
import * as Types from './operations'

export const GetCalendarEventsDocument = `
    query getCalendarEvents($clinicId: UUID4!, $filter: AppointmentFilter!) {
  appointments(
    clinicId: $clinicId
    filter: $filter
    pagination: {pageSize: 100, page: 1}
  ) {
    entries {
      appointmentType {
        name
      }
      id
      patient {
        name
      }
      assignedStaff {
        name
        jobTitle
      }
      startsAt
      endsAt
    }
  }
}
    `
export const useGetCalendarEventsQuery = <
  TData = Types.GetCalendarEventsQuery,
  TError = GraphqlApiError
>(
  variables: Types.GetCalendarEventsQueryVariables,
  options?: UseQueryOptions<Types.GetCalendarEventsQuery, TError, TData>
) =>
  useQuery<Types.GetCalendarEventsQuery, TError, TData>(
    ['getCalendarEvents', variables],
    createFetcher<
      Types.GetCalendarEventsQuery,
      Types.GetCalendarEventsQueryVariables
    >(GetCalendarEventsDocument, variables),
    options
  )
useGetCalendarEventsQuery.getKey = (
  variables: Types.GetCalendarEventsQueryVariables
) => ['getCalendarEvents', variables]

export const GetAppointmentByIdDocument = `
    query getAppointmentById($id: UUID4!) {
  appointment(appointmentId: $id) {
    appointmentType {
      name
    }
    status
    formRequest {
      patientCallAvailability
      patientCountry
      patientEmail
      patientName
      patientPhoneNumber
      message
      language
    }
    patient {
      country
      email
      name
      phoneNumber
    }
    startsAt
    endsAt
    insurance
    assignedStaff {
      name
      email
    }
    callAccesses {
      code
      role
      url
    }
    callParticipants {
      email
    }
    internalNotes {
      id
      createdBy {
        name
      }
      insertedAt
      message
    }
    editBy {
      name
      email
    }
  }
}
    `
export const useGetAppointmentByIdQuery = <
  TData = Types.GetAppointmentByIdQuery,
  TError = GraphqlApiError
>(
  variables: Types.GetAppointmentByIdQueryVariables,
  options?: UseQueryOptions<Types.GetAppointmentByIdQuery, TError, TData>
) =>
  useQuery<Types.GetAppointmentByIdQuery, TError, TData>(
    ['getAppointmentById', variables],
    createFetcher<
      Types.GetAppointmentByIdQuery,
      Types.GetAppointmentByIdQueryVariables
    >(GetAppointmentByIdDocument, variables),
    options
  )
useGetAppointmentByIdQuery.getKey = (
  variables: Types.GetAppointmentByIdQueryVariables
) => ['getAppointmentById', variables]

export const CancelAppointmentDocument = `
    mutation cancelAppointment($id: UUID4!) {
  updateAppointment(appointmentId: $id, status: CANCELED) {
    id
  }
}
    `
export const useCancelAppointmentMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.CancelAppointmentMutation,
    TError,
    Types.CancelAppointmentMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.CancelAppointmentMutation,
    TError,
    Types.CancelAppointmentMutationVariables,
    TContext
  >(
    (variables?: Types.CancelAppointmentMutationVariables) =>
      createFetcher<
        Types.CancelAppointmentMutation,
        Types.CancelAppointmentMutationVariables
      >(CancelAppointmentDocument, variables)(),
    options
  )
export const CreateAppointmentDocument = `
    mutation createAppointment($clinicId: UUID4!, $status: AppointmentStatus!, $appointmentTypeId: UUID4, $assignedStaffId: UUID4, $endsAt: DateTime, $externalNote: String, $insurance: String, $patientId: UUID4, $startsAt: DateTime) {
  createAppointment(
    clinicId: $clinicId
    appointmentTypeId: $appointmentTypeId
    assignedStaffId: $assignedStaffId
    endsAt: $endsAt
    externalNote: $externalNote
    insurance: $insurance
    patientId: $patientId
    startsAt: $startsAt
    status: $status
  ) {
    id
  }
}
    `
export const useCreateAppointmentMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.CreateAppointmentMutation,
    TError,
    Types.CreateAppointmentMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.CreateAppointmentMutation,
    TError,
    Types.CreateAppointmentMutationVariables,
    TContext
  >(
    (variables?: Types.CreateAppointmentMutationVariables) =>
      createFetcher<
        Types.CreateAppointmentMutation,
        Types.CreateAppointmentMutationVariables
      >(CreateAppointmentDocument, variables)(),
    options
  )
export const UpdateAppointmentDocument = `
    mutation updateAppointment($appointmentId: UUID4!, $status: AppointmentStatus!, $appointmentTypeId: UUID4, $assignedStaffId: UUID4, $endsAt: DateTime, $externalNote: String, $insurance: String, $patientId: UUID4, $startsAt: DateTime) {
  updateAppointment(
    appointmentId: $appointmentId
    appointmentTypeId: $appointmentTypeId
    assignedStaffId: $assignedStaffId
    endsAt: $endsAt
    externalNote: $externalNote
    insurance: $insurance
    patientId: $patientId
    startsAt: $startsAt
    status: $status
  ) {
    id
  }
}
    `
export const useUpdateAppointmentMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.UpdateAppointmentMutation,
    TError,
    Types.UpdateAppointmentMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.UpdateAppointmentMutation,
    TError,
    Types.UpdateAppointmentMutationVariables,
    TContext
  >(
    (variables?: Types.UpdateAppointmentMutationVariables) =>
      createFetcher<
        Types.UpdateAppointmentMutation,
        Types.UpdateAppointmentMutationVariables
      >(UpdateAppointmentDocument, variables)(),
    options
  )
export const GetStaffAndTypesDocument = `
    query getStaffAndTypes($clinicId: UUID4!) {
  clinic(clinicId: $clinicId) {
    appointmentTypes {
      id
      name
    }
  }
  users(clinicId: $clinicId, isStaff: true, pagination: {page: 1, pageSize: 100}) {
    entries {
      id
      name
    }
  }
}
    `
export const useGetStaffAndTypesQuery = <
  TData = Types.GetStaffAndTypesQuery,
  TError = GraphqlApiError
>(
  variables: Types.GetStaffAndTypesQueryVariables,
  options?: UseQueryOptions<Types.GetStaffAndTypesQuery, TError, TData>
) =>
  useQuery<Types.GetStaffAndTypesQuery, TError, TData>(
    ['getStaffAndTypes', variables],
    createFetcher<
      Types.GetStaffAndTypesQuery,
      Types.GetStaffAndTypesQueryVariables
    >(GetStaffAndTypesDocument, variables),
    options
  )
useGetStaffAndTypesQuery.getKey = (
  variables: Types.GetStaffAndTypesQueryVariables
) => ['getStaffAndTypes', variables]

export const GetParticipantsDocument = `
    query getParticipants($appointmentId: UUID4!) {
  appointment(appointmentId: $appointmentId) {
    callParticipants {
      email
    }
  }
}
    `
export const useGetParticipantsQuery = <
  TData = Types.GetParticipantsQuery,
  TError = GraphqlApiError
>(
  variables: Types.GetParticipantsQueryVariables,
  options?: UseQueryOptions<Types.GetParticipantsQuery, TError, TData>
) =>
  useQuery<Types.GetParticipantsQuery, TError, TData>(
    ['getParticipants', variables],
    createFetcher<
      Types.GetParticipantsQuery,
      Types.GetParticipantsQueryVariables
    >(GetParticipantsDocument, variables),
    options
  )
useGetParticipantsQuery.getKey = (
  variables: Types.GetParticipantsQueryVariables
) => ['getParticipants', variables]

export const AddParticipantDocument = `
    mutation addParticipant($appointmentId: UUID4!, $email: String!) {
  addAppointmentParticipant(appointmentId: $appointmentId, email: $email) {
    id
    callParticipants {
      email
    }
  }
}
    `
export const useAddParticipantMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.AddParticipantMutation,
    TError,
    Types.AddParticipantMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.AddParticipantMutation,
    TError,
    Types.AddParticipantMutationVariables,
    TContext
  >(
    (variables?: Types.AddParticipantMutationVariables) =>
      createFetcher<
        Types.AddParticipantMutation,
        Types.AddParticipantMutationVariables
      >(AddParticipantDocument, variables)(),
    options
  )
export const RemoveParticipantDocument = `
    mutation removeParticipant($appointmentId: UUID4!, $email: String!) {
  removeAppointmentParticipant(appointmentId: $appointmentId, email: $email) {
    id
    callParticipants {
      email
    }
  }
}
    `
export const useRemoveParticipantMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.RemoveParticipantMutation,
    TError,
    Types.RemoveParticipantMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.RemoveParticipantMutation,
    TError,
    Types.RemoveParticipantMutationVariables,
    TContext
  >(
    (variables?: Types.RemoveParticipantMutationVariables) =>
      createFetcher<
        Types.RemoveParticipantMutation,
        Types.RemoveParticipantMutationVariables
      >(RemoveParticipantDocument, variables)(),
    options
  )
export const GetPatientsForSelectorDocument = `
    query getPatientsForSelector($clinicId: UUID4!, $pagination: Pagination, $search: PatientSearch) {
  patients(clinicId: $clinicId, pagination: $pagination, search: $search) {
    entries {
      id
      name
      externalId
      email
      phoneNumber
    }
    totalPages
  }
}
    `
export const useGetPatientsForSelectorQuery = <
  TData = Types.GetPatientsForSelectorQuery,
  TError = GraphqlApiError
>(
  variables: Types.GetPatientsForSelectorQueryVariables,
  options?: UseQueryOptions<Types.GetPatientsForSelectorQuery, TError, TData>
) =>
  useQuery<Types.GetPatientsForSelectorQuery, TError, TData>(
    ['getPatientsForSelector', variables],
    createFetcher<
      Types.GetPatientsForSelectorQuery,
      Types.GetPatientsForSelectorQueryVariables
    >(GetPatientsForSelectorDocument, variables),
    options
  )
useGetPatientsForSelectorQuery.getKey = (
  variables: Types.GetPatientsForSelectorQueryVariables
) => ['getPatientsForSelector', variables]

export const GetAppointmentsDocument = `
    query getAppointments($clinicId: UUID4!, $pagination: Pagination, $filter: AppointmentFilter, $sorting: [AppointmentSort!], $searchTerm: String) {
  appointments(
    clinicId: $clinicId
    pagination: $pagination
    filter: $filter
    sorting: $sorting
    searchTerm: $searchTerm
  ) {
    totalEntries
    totalPages
    entries {
      insertedAt
      id
      appointmentType {
        name
      }
      patient {
        name
        externalId
        email
        phoneNumber
      }
      assignedStaff {
        name
        email
      }
      status
      formRequest {
        patientName
        patientPhoneNumber
        patientEmail
        patientCallAvailability
      }
      editBy {
        name
        email
      }
    }
  }
}
    `
export const useGetAppointmentsQuery = <
  TData = Types.GetAppointmentsQuery,
  TError = GraphqlApiError
>(
  variables: Types.GetAppointmentsQueryVariables,
  options?: UseQueryOptions<Types.GetAppointmentsQuery, TError, TData>
) =>
  useQuery<Types.GetAppointmentsQuery, TError, TData>(
    ['getAppointments', variables],
    createFetcher<
      Types.GetAppointmentsQuery,
      Types.GetAppointmentsQueryVariables
    >(GetAppointmentsDocument, variables),
    options
  )
useGetAppointmentsQuery.getKey = (
  variables: Types.GetAppointmentsQueryVariables
) => ['getAppointments', variables]

export const GetInternalNotesDocument = `
    query getInternalNotes($appointmentId: UUID4!) {
  appointment(appointmentId: $appointmentId) {
    internalNotes {
      id
      createdBy {
        name
      }
      insertedAt
      message
    }
  }
}
    `
export const useGetInternalNotesQuery = <
  TData = Types.GetInternalNotesQuery,
  TError = GraphqlApiError
>(
  variables: Types.GetInternalNotesQueryVariables,
  options?: UseQueryOptions<Types.GetInternalNotesQuery, TError, TData>
) =>
  useQuery<Types.GetInternalNotesQuery, TError, TData>(
    ['getInternalNotes', variables],
    createFetcher<
      Types.GetInternalNotesQuery,
      Types.GetInternalNotesQueryVariables
    >(GetInternalNotesDocument, variables),
    options
  )
useGetInternalNotesQuery.getKey = (
  variables: Types.GetInternalNotesQueryVariables
) => ['getInternalNotes', variables]

export const AddInternalNoteDocument = `
    mutation addInternalNote($appointmentId: UUID4!, $message: String!) {
  addInternalNote(appointmentId: $appointmentId, message: $message) {
    id
  }
}
    `
export const useAddInternalNoteMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.AddInternalNoteMutation,
    TError,
    Types.AddInternalNoteMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.AddInternalNoteMutation,
    TError,
    Types.AddInternalNoteMutationVariables,
    TContext
  >(
    (variables?: Types.AddInternalNoteMutationVariables) =>
      createFetcher<
        Types.AddInternalNoteMutation,
        Types.AddInternalNoteMutationVariables
      >(AddInternalNoteDocument, variables)(),
    options
  )
export const GetUserClinicsDocument = `
    query getUserClinics {
  me {
    clinicUsers(hasRole: true) {
      role
      clinic {
        id
        displayName
        timezone
        defaultPatientLanguage
        defaultPatientCountry
      }
    }
  }
}
    `
export const useGetUserClinicsQuery = <
  TData = Types.GetUserClinicsQuery,
  TError = GraphqlApiError
>(
  variables?: Types.GetUserClinicsQueryVariables,
  options?: UseQueryOptions<Types.GetUserClinicsQuery, TError, TData>
) =>
  useQuery<Types.GetUserClinicsQuery, TError, TData>(
    ['getUserClinics', variables],
    createFetcher<
      Types.GetUserClinicsQuery,
      Types.GetUserClinicsQueryVariables
    >(GetUserClinicsDocument, variables),
    options
  )
useGetUserClinicsQuery.getKey = (
  variables?: Types.GetUserClinicsQueryVariables
) => ['getUserClinics', variables]

export const AddAdminToClinicDocument = `
    mutation addAdminToClinic($clinicId: UUID4!, $email: String!, $name: String!) {
  createOrUpdateUser(clinicId: $clinicId, email: $email, name: $name, role: ADMIN) {
    id
  }
}
    `
export const useAddAdminToClinicMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.AddAdminToClinicMutation,
    TError,
    Types.AddAdminToClinicMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.AddAdminToClinicMutation,
    TError,
    Types.AddAdminToClinicMutationVariables,
    TContext
  >(
    (variables?: Types.AddAdminToClinicMutationVariables) =>
      createFetcher<
        Types.AddAdminToClinicMutation,
        Types.AddAdminToClinicMutationVariables
      >(AddAdminToClinicDocument, variables)(),
    options
  )
export const CreateClinicDocument = `
    mutation createClinic($displayName: String!, $internalId: String!, $timezone: String!) {
  createClinic(
    displayName: $displayName
    internalId: $internalId
    timezone: $timezone
  ) {
    id
  }
}
    `
export const useCreateClinicMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.CreateClinicMutation,
    TError,
    Types.CreateClinicMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.CreateClinicMutation,
    TError,
    Types.CreateClinicMutationVariables,
    TContext
  >(
    (variables?: Types.CreateClinicMutationVariables) =>
      createFetcher<
        Types.CreateClinicMutation,
        Types.CreateClinicMutationVariables
      >(CreateClinicDocument, variables)(),
    options
  )
export const GetTimezonesDocument = `
    query getTimezones {
  timezones
}
    `
export const useGetTimezonesQuery = <
  TData = Types.GetTimezonesQuery,
  TError = GraphqlApiError
>(
  variables?: Types.GetTimezonesQueryVariables,
  options?: UseQueryOptions<Types.GetTimezonesQuery, TError, TData>
) =>
  useQuery<Types.GetTimezonesQuery, TError, TData>(
    ['getTimezones', variables],
    createFetcher<Types.GetTimezonesQuery, Types.GetTimezonesQueryVariables>(
      GetTimezonesDocument,
      variables
    ),
    options
  )
useGetTimezonesQuery.getKey = (
  variables?: Types.GetTimezonesQueryVariables
) => ['getTimezones', variables]

export const GetClinicsDocument = `
    query getClinics($pagination: Pagination) {
  clinics(pagination: $pagination) {
    totalPages
    entries {
      id
      displayName
      internalId
      timezone
    }
  }
}
    `
export const useGetClinicsQuery = <
  TData = Types.GetClinicsQuery,
  TError = GraphqlApiError
>(
  variables?: Types.GetClinicsQueryVariables,
  options?: UseQueryOptions<Types.GetClinicsQuery, TError, TData>
) =>
  useQuery<Types.GetClinicsQuery, TError, TData>(
    ['getClinics', variables],
    createFetcher<Types.GetClinicsQuery, Types.GetClinicsQueryVariables>(
      GetClinicsDocument,
      variables
    ),
    options
  )
useGetClinicsQuery.getKey = (variables?: Types.GetClinicsQueryVariables) => [
  'getClinics',
  variables,
]

export const GetHeaderClinicsDocument = `
    query getHeaderClinics {
  me {
    clinicUsers(hasRole: true) {
      clinic {
        id
      }
    }
  }
}
    `
export const useGetHeaderClinicsQuery = <
  TData = Types.GetHeaderClinicsQuery,
  TError = GraphqlApiError
>(
  variables?: Types.GetHeaderClinicsQueryVariables,
  options?: UseQueryOptions<Types.GetHeaderClinicsQuery, TError, TData>
) =>
  useQuery<Types.GetHeaderClinicsQuery, TError, TData>(
    ['getHeaderClinics', variables],
    createFetcher<
      Types.GetHeaderClinicsQuery,
      Types.GetHeaderClinicsQueryVariables
    >(GetHeaderClinicsDocument, variables),
    options
  )
useGetHeaderClinicsQuery.getKey = (
  variables?: Types.GetHeaderClinicsQueryVariables
) => ['getHeaderClinics', variables]

export const GetLogsDocument = `
    query getLogs($pagination: Pagination, $filters: AuditLogsFilters) {
  auditLogs(pagination: $pagination, filters: $filters) {
    totalPages
    totalEntries
    entries {
      id
      operationName
      operationType
      arguments
      ip
      insertedAt
    }
  }
}
    `
export const useGetLogsQuery = <
  TData = Types.GetLogsQuery,
  TError = GraphqlApiError
>(
  variables?: Types.GetLogsQueryVariables,
  options?: UseQueryOptions<Types.GetLogsQuery, TError, TData>
) =>
  useQuery<Types.GetLogsQuery, TError, TData>(
    ['getLogs', variables],
    createFetcher<Types.GetLogsQuery, Types.GetLogsQueryVariables>(
      GetLogsDocument,
      variables
    ),
    options
  )
useGetLogsQuery.getKey = (variables?: Types.GetLogsQueryVariables) => [
  'getLogs',
  variables,
]

export const CreatePatientDocument = `
    mutation createPatient($clinicId: UUID4!, $email: String!, $name: String!, $country: String, $phoneNumber: String, $language: String, $externalId: String) {
  createPatient(
    clinicId: $clinicId
    email: $email
    name: $name
    country: $country
    phoneNumber: $phoneNumber
    language: $language
    externalId: $externalId
  ) {
    id
  }
}
    `
export const useCreatePatientMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.CreatePatientMutation,
    TError,
    Types.CreatePatientMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.CreatePatientMutation,
    TError,
    Types.CreatePatientMutationVariables,
    TContext
  >(
    (variables?: Types.CreatePatientMutationVariables) =>
      createFetcher<
        Types.CreatePatientMutation,
        Types.CreatePatientMutationVariables
      >(CreatePatientDocument, variables)(),
    options
  )
export const UpdatePatientDocument = `
    mutation updatePatient($patientId: UUID4!, $email: String!, $name: String!, $country: String, $phoneNumber: String, $language: String, $externalId: String) {
  updatePatient(
    patientId: $patientId
    email: $email
    name: $name
    country: $country
    phoneNumber: $phoneNumber
    language: $language
    externalId: $externalId
  ) {
    id
  }
}
    `
export const useUpdatePatientMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.UpdatePatientMutation,
    TError,
    Types.UpdatePatientMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.UpdatePatientMutation,
    TError,
    Types.UpdatePatientMutationVariables,
    TContext
  >(
    (variables?: Types.UpdatePatientMutationVariables) =>
      createFetcher<
        Types.UpdatePatientMutation,
        Types.UpdatePatientMutationVariables
      >(UpdatePatientDocument, variables)(),
    options
  )
export const GetPatientsDocument = `
    query getPatients($clinicId: UUID4!, $pagination: Pagination, $search: PatientSearch) {
  patients(clinicId: $clinicId, pagination: $pagination, search: $search) {
    totalPages
    entries {
      id
      email
      name
      externalId
      phoneNumber
      country
      language
    }
  }
}
    `
export const useGetPatientsQuery = <
  TData = Types.GetPatientsQuery,
  TError = GraphqlApiError
>(
  variables: Types.GetPatientsQueryVariables,
  options?: UseQueryOptions<Types.GetPatientsQuery, TError, TData>
) =>
  useQuery<Types.GetPatientsQuery, TError, TData>(
    ['getPatients', variables],
    createFetcher<Types.GetPatientsQuery, Types.GetPatientsQueryVariables>(
      GetPatientsDocument,
      variables
    ),
    options
  )
useGetPatientsQuery.getKey = (variables: Types.GetPatientsQueryVariables) => [
  'getPatients',
  variables,
]

export const DeletePatientDocument = `
    mutation deletePatient($patientId: UUID4!) {
  deletePatient(patientId: $patientId) {
    id
  }
}
    `
export const useDeletePatientMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.DeletePatientMutation,
    TError,
    Types.DeletePatientMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.DeletePatientMutation,
    TError,
    Types.DeletePatientMutationVariables,
    TContext
  >(
    (variables?: Types.DeletePatientMutationVariables) =>
      createFetcher<
        Types.DeletePatientMutation,
        Types.DeletePatientMutationVariables
      >(DeletePatientDocument, variables)(),
    options
  )
export const CreateAppointmentTypeDocument = `
    mutation createAppointmentType($clinicId: UUID4!, $name: String!) {
  createAppointmentType(clinicId: $clinicId, name: $name) {
    id
  }
}
    `
export const useCreateAppointmentTypeMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.CreateAppointmentTypeMutation,
    TError,
    Types.CreateAppointmentTypeMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.CreateAppointmentTypeMutation,
    TError,
    Types.CreateAppointmentTypeMutationVariables,
    TContext
  >(
    (variables?: Types.CreateAppointmentTypeMutationVariables) =>
      createFetcher<
        Types.CreateAppointmentTypeMutation,
        Types.CreateAppointmentTypeMutationVariables
      >(CreateAppointmentTypeDocument, variables)(),
    options
  )
export const UpdateAppointmentTypeDocument = `
    mutation updateAppointmentType($id: UUID4!, $name: String!) {
  updateAppointmentType(appointmentTypeId: $id, name: $name) {
    id
  }
}
    `
export const useUpdateAppointmentTypeMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.UpdateAppointmentTypeMutation,
    TError,
    Types.UpdateAppointmentTypeMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.UpdateAppointmentTypeMutation,
    TError,
    Types.UpdateAppointmentTypeMutationVariables,
    TContext
  >(
    (variables?: Types.UpdateAppointmentTypeMutationVariables) =>
      createFetcher<
        Types.UpdateAppointmentTypeMutation,
        Types.UpdateAppointmentTypeMutationVariables
      >(UpdateAppointmentTypeDocument, variables)(),
    options
  )
export const GetTypesDocument = `
    query getTypes($clinicId: UUID4!, $pagination: Pagination) {
  appointmentTypes(clinicId: $clinicId, pagination: $pagination) {
    totalPages
    entries {
      id
      isEnabled
      name
    }
  }
}
    `
export const useGetTypesQuery = <
  TData = Types.GetTypesQuery,
  TError = GraphqlApiError
>(
  variables: Types.GetTypesQueryVariables,
  options?: UseQueryOptions<Types.GetTypesQuery, TError, TData>
) =>
  useQuery<Types.GetTypesQuery, TError, TData>(
    ['getTypes', variables],
    createFetcher<Types.GetTypesQuery, Types.GetTypesQueryVariables>(
      GetTypesDocument,
      variables
    ),
    options
  )
useGetTypesQuery.getKey = (variables: Types.GetTypesQueryVariables) => [
  'getTypes',
  variables,
]

export const EnableTypeDocument = `
    mutation enableType($id: UUID4!) {
  enableAppointmentType(appointmentTypeId: $id) {
    id
    isEnabled
  }
}
    `
export const useEnableTypeMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.EnableTypeMutation,
    TError,
    Types.EnableTypeMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.EnableTypeMutation,
    TError,
    Types.EnableTypeMutationVariables,
    TContext
  >(
    (variables?: Types.EnableTypeMutationVariables) =>
      createFetcher<
        Types.EnableTypeMutation,
        Types.EnableTypeMutationVariables
      >(EnableTypeDocument, variables)(),
    options
  )
export const DisableTypeDocument = `
    mutation disableType($id: UUID4!) {
  disableAppointmentType(appointmentTypeId: $id) {
    id
    isEnabled
  }
}
    `
export const useDisableTypeMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.DisableTypeMutation,
    TError,
    Types.DisableTypeMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.DisableTypeMutation,
    TError,
    Types.DisableTypeMutationVariables,
    TContext
  >(
    (variables?: Types.DisableTypeMutationVariables) =>
      createFetcher<
        Types.DisableTypeMutation,
        Types.DisableTypeMutationVariables
      >(DisableTypeDocument, variables)(),
    options
  )
export const CreateOrUpdateUserDocument = `
    mutation createOrUpdateUser($clinicId: UUID4!, $email: String!, $isStaff: Boolean, $name: String, $role: UserRole, $jobTitle: String) {
  createOrUpdateUser(
    clinicId: $clinicId
    email: $email
    isStaff: $isStaff
    name: $name
    role: $role
    jobTitle: $jobTitle
  ) {
    id
  }
}
    `
export const useCreateOrUpdateUserMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.CreateOrUpdateUserMutation,
    TError,
    Types.CreateOrUpdateUserMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.CreateOrUpdateUserMutation,
    TError,
    Types.CreateOrUpdateUserMutationVariables,
    TContext
  >(
    (variables?: Types.CreateOrUpdateUserMutationVariables) =>
      createFetcher<
        Types.CreateOrUpdateUserMutation,
        Types.CreateOrUpdateUserMutationVariables
      >(CreateOrUpdateUserDocument, variables)(),
    options
  )
export const GetUsersDocument = `
    query getUsers($clinicId: UUID4!, $pagination: Pagination, $search: String, $hasRole: Boolean, $isStaff: Boolean) {
  clinicUsers(
    clinicId: $clinicId
    pagination: $pagination
    searchTerm: $search
    hasRole: $hasRole
    isStaff: $isStaff
  ) {
    totalPages
    entries {
      role
      user {
        id
        email
        name
        jobTitle
      }
    }
  }
}
    `
export const useGetUsersQuery = <
  TData = Types.GetUsersQuery,
  TError = GraphqlApiError
>(
  variables: Types.GetUsersQueryVariables,
  options?: UseQueryOptions<Types.GetUsersQuery, TError, TData>
) =>
  useQuery<Types.GetUsersQuery, TError, TData>(
    ['getUsers', variables],
    createFetcher<Types.GetUsersQuery, Types.GetUsersQueryVariables>(
      GetUsersDocument,
      variables
    ),
    options
  )
useGetUsersQuery.getKey = (variables: Types.GetUsersQueryVariables) => [
  'getUsers',
  variables,
]

export const UpdateUserDocument = `
    mutation updateUser($clinicId: UUID4!, $email: String!, $role: UserRole, $isStaff: Boolean, $jobTitle: String) {
  createOrUpdateUser(
    clinicId: $clinicId
    email: $email
    isStaff: $isStaff
    role: $role
    jobTitle: $jobTitle
  ) {
    id
  }
}
    `
export const useUpdateUserMutation = <
  TError = GraphqlApiError,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.UpdateUserMutation,
    TError,
    Types.UpdateUserMutationVariables,
    TContext
  >
) =>
  useMutation<
    Types.UpdateUserMutation,
    TError,
    Types.UpdateUserMutationVariables,
    TContext
  >(
    (variables?: Types.UpdateUserMutationVariables) =>
      createFetcher<
        Types.UpdateUserMutation,
        Types.UpdateUserMutationVariables
      >(UpdateUserDocument, variables)(),
    options
  )
export const GetEmailForUsersListDocument = `
    query getEmailForUsersList {
  me {
    email
  }
}
    `
export const useGetEmailForUsersListQuery = <
  TData = Types.GetEmailForUsersListQuery,
  TError = GraphqlApiError
>(
  variables?: Types.GetEmailForUsersListQueryVariables,
  options?: UseQueryOptions<Types.GetEmailForUsersListQuery, TError, TData>
) =>
  useQuery<Types.GetEmailForUsersListQuery, TError, TData>(
    ['getEmailForUsersList', variables],
    createFetcher<
      Types.GetEmailForUsersListQuery,
      Types.GetEmailForUsersListQueryVariables
    >(GetEmailForUsersListDocument, variables),
    options
  )
useGetEmailForUsersListQuery.getKey = (
  variables?: Types.GetEmailForUsersListQueryVariables
) => ['getEmailForUsersList', variables]

export const GetCallStatusDocument = `
    query getCallStatus($id: UUID4!) {
  callStatus(appointmentId: $id) {
    clientCount
  }
}
    `
export const useGetCallStatusQuery = <
  TData = Types.GetCallStatusQuery,
  TError = GraphqlApiError
>(
  variables: Types.GetCallStatusQueryVariables,
  options?: UseQueryOptions<Types.GetCallStatusQuery, TError, TData>
) =>
  useQuery<Types.GetCallStatusQuery, TError, TData>(
    ['getCallStatus', variables],
    createFetcher<Types.GetCallStatusQuery, Types.GetCallStatusQueryVariables>(
      GetCallStatusDocument,
      variables
    ),
    options
  )
useGetCallStatusQuery.getKey = (
  variables: Types.GetCallStatusQueryVariables
) => ['getCallStatus', variables]

export const GetStaffForFilterDocument = `
    query getStaffForFilter($clinicId: UUID4!, $searchTerm: String) {
  clinicUsers(
    clinicId: $clinicId
    searchTerm: $searchTerm
    isStaff: true
    pagination: {page: 1, pageSize: 5}
  ) {
    totalEntries
    entries {
      user {
        name
        email
        id
      }
    }
  }
}
    `
export const useGetStaffForFilterQuery = <
  TData = Types.GetStaffForFilterQuery,
  TError = GraphqlApiError
>(
  variables: Types.GetStaffForFilterQueryVariables,
  options?: UseQueryOptions<Types.GetStaffForFilterQuery, TError, TData>
) =>
  useQuery<Types.GetStaffForFilterQuery, TError, TData>(
    ['getStaffForFilter', variables],
    createFetcher<
      Types.GetStaffForFilterQuery,
      Types.GetStaffForFilterQueryVariables
    >(GetStaffForFilterDocument, variables),
    options
  )
useGetStaffForFilterQuery.getKey = (
  variables: Types.GetStaffForFilterQueryVariables
) => ['getStaffForFilter', variables]

export const GetMeForStaffFilterDocument = `
    query getMeForStaffFilter {
  me {
    id
  }
}
    `
export const useGetMeForStaffFilterQuery = <
  TData = Types.GetMeForStaffFilterQuery,
  TError = GraphqlApiError
>(
  variables?: Types.GetMeForStaffFilterQueryVariables,
  options?: UseQueryOptions<Types.GetMeForStaffFilterQuery, TError, TData>
) =>
  useQuery<Types.GetMeForStaffFilterQuery, TError, TData>(
    ['getMeForStaffFilter', variables],
    createFetcher<
      Types.GetMeForStaffFilterQuery,
      Types.GetMeForStaffFilterQueryVariables
    >(GetMeForStaffFilterDocument, variables),
    options
  )
useGetMeForStaffFilterQuery.getKey = (
  variables?: Types.GetMeForStaffFilterQueryVariables
) => ['getMeForStaffFilter', variables]

export const GetAppointmentForFormDocument = `
    query getAppointmentForForm($appointmentId: UUID4!) {
  appointment(appointmentId: $appointmentId) {
    id
    status
    appointmentType {
      id
    }
    assignedStaff {
      id
    }
    endsAt
    externalNote
    insurance
    patient {
      id
      name
      externalId
      email
      phoneNumber
    }
    startsAt
    formRequest {
      patientCallAvailability
      patientCountry
      patientEmail
      patientName
      patientPhoneNumber
      message
      language
    }
  }
}
    `
export const useGetAppointmentForFormQuery = <
  TData = Types.GetAppointmentForFormQuery,
  TError = GraphqlApiError
>(
  variables: Types.GetAppointmentForFormQueryVariables,
  options?: UseQueryOptions<Types.GetAppointmentForFormQuery, TError, TData>
) =>
  useQuery<Types.GetAppointmentForFormQuery, TError, TData>(
    ['getAppointmentForForm', variables],
    createFetcher<
      Types.GetAppointmentForFormQuery,
      Types.GetAppointmentForFormQueryVariables
    >(GetAppointmentForFormDocument, variables),
    options
  )
useGetAppointmentForFormQuery.getKey = (
  variables: Types.GetAppointmentForFormQueryVariables
) => ['getAppointmentForForm', variables]

export const StartLoginDocument = `
    query startLogin($redirectTo: String!) {
  getAuthorizeUrl(redirectUri: $redirectTo)
}
    `
export const useStartLoginQuery = <
  TData = Types.StartLoginQuery,
  TError = GraphqlApiError
>(
  variables: Types.StartLoginQueryVariables,
  options?: UseQueryOptions<Types.StartLoginQuery, TError, TData>
) =>
  useQuery<Types.StartLoginQuery, TError, TData>(
    ['startLogin', variables],
    createFetcher<Types.StartLoginQuery, Types.StartLoginQueryVariables>(
      StartLoginDocument,
      variables
    ),
    options
  )
useStartLoginQuery.getKey = (variables: Types.StartLoginQueryVariables) => [
  'startLogin',
  variables,
]

export const GetAuthorizationDocument = `
    query getAuthorization($callbackUri: String!) {
  getAuthorization(callbackUri: $callbackUri) {
    accessToken
    expiresAt
    tokenType
  }
}
    `
export const useGetAuthorizationQuery = <
  TData = Types.GetAuthorizationQuery,
  TError = GraphqlApiError
>(
  variables: Types.GetAuthorizationQueryVariables,
  options?: UseQueryOptions<Types.GetAuthorizationQuery, TError, TData>
) =>
  useQuery<Types.GetAuthorizationQuery, TError, TData>(
    ['getAuthorization', variables],
    createFetcher<
      Types.GetAuthorizationQuery,
      Types.GetAuthorizationQueryVariables
    >(GetAuthorizationDocument, variables),
    options
  )
useGetAuthorizationQuery.getKey = (
  variables: Types.GetAuthorizationQueryVariables
) => ['getAuthorization', variables]
