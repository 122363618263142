import { BoxProps, Box } from '@chakra-ui/react'
import React from 'react'

export const Logo = (props: BoxProps): JSX.Element => {
  return (
    <Box as="span" {...props}>
      <svg
        width="88"
        height="22"
        viewBox="0 0 88 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.781544 2.96628C0.355785 2.96628 -0.000691986 3.3076 1.00869e-06 3.74246C0.014926 13.1081 7.7532 21.0337 17.2653 21.0337C21.5751 21.0337 25.7578 19.3465 28.6267 16.642C29.0158 16.2752 28.6848 15.6783 28.1973 15.7322C23.9321 16.2035 17.0074 16.3179 12.2463 14.3133C7.24862 12.209 3.88133 7.60162 1.46922 3.36647C1.32857 3.11952 1.06666 2.96628 0.781544 2.96628Z"
          fill="#245A80"
        />
        <path
          d="M33.5393 2.96628C33.2082 2.96628 32.9055 3.12782 32.71 3.38099C31.9302 4.39066 29.1203 7.66767 24.4461 9.2424C20.4034 10.6044 15.233 10.9867 12.6544 11.0932C12.2859 11.1084 12.0076 11.3384 11.9263 11.6393C11.8421 11.9511 11.9843 12.2865 12.334 12.448C14.876 13.6219 19.1733 14.5105 23.304 14.2448C27.4273 13.9795 31.4747 12.5553 33.3897 9.00494C34.1314 7.62985 34.5437 5.95048 34.523 3.92358C34.5175 3.37998 34.0665 2.96628 33.5393 2.96628Z"
          fill="#245A80"
        />
        <path
          d="M26.0523 5.21491C24.8113 4.29437 21.5673 2.3176 17.4793 3.17823C13.4685 4.02261 11.5462 6.79808 10.8576 8.07362C10.5874 8.57418 10.9444 9.12611 11.4778 9.15997C16.6876 9.49072 22.8125 8.14016 25.9573 6.45789C26.4399 6.19975 26.498 5.54551 26.0523 5.21491Z"
          fill="#AB4478"
        />
        <circle cx="8.09618" cy="3.23595" r="3.23595" fill="#AB4478" />
        <path
          d="M55.6913 1.8339C55.9586 1.8339 56.1753 2.05059 56.1753 2.3179V3.47921C56.1753 4.54843 55.3085 5.4152 54.2393 5.4152H50.5475V18.5584C50.5475 19.6276 49.6807 20.4944 48.6115 20.4944H46.8309C46.5636 20.4944 46.3469 20.2777 46.3469 20.0104V5.4152H41.2031C40.9358 5.4152 40.7191 5.19851 40.7191 4.9312V3.7699C40.7191 2.70067 41.5859 1.8339 42.6551 1.8339H55.6913Z"
          fill="#245A80"
        />
        <path
          d="M79.0299 1.61798C81.8124 1.61798 83.9486 2.11164 85.4385 3.09897C86.9285 4.06835 87.6735 5.66602 87.6735 7.892C87.6735 10.1359 86.9195 11.7605 85.4116 12.7658C83.9037 13.7531 81.7495 14.2468 78.9491 14.2468H77.6297V18.5579C77.6297 19.6271 76.7629 20.4939 75.6937 20.4939H73.9131C73.6457 20.4939 73.4291 20.2772 73.4291 20.0099V3.69593C73.4291 2.77032 74.0837 1.96646 75.0024 1.85364C75.4366 1.80031 75.8816 1.75766 76.3372 1.72569C77.3604 1.65388 78.258 1.61798 79.0299 1.61798ZM79.2992 5.19929C78.994 5.19929 78.6888 5.20826 78.3836 5.22621C78.0964 5.24416 77.8451 5.26212 77.6297 5.28007V10.6655H78.9491C80.4032 10.6655 81.4982 10.468 82.2342 10.0731C82.9702 9.67816 83.3382 8.94216 83.3382 7.86507C83.3382 7.34448 83.2395 6.91365 83.042 6.57257C82.8625 6.23149 82.5932 5.96222 82.2342 5.76476C81.8931 5.54934 81.4713 5.40573 80.9686 5.33392C80.466 5.24417 79.9095 5.19929 79.2992 5.19929Z"
          fill="#245A80"
        />
        <path
          d="M58.8122 20.4944C58.5449 20.4944 58.3282 20.2777 58.3282 20.0104V2.8019C58.3282 2.26729 58.7616 1.8339 59.2962 1.8339H70.3384C70.6057 1.8339 70.8224 2.05059 70.8224 2.3179V3.42535C70.8224 4.49457 69.9556 5.36135 68.8864 5.36135H62.5288V9.26578H69.4229C69.6902 9.26578 69.9069 9.48248 69.9069 9.74978V10.8572C69.9069 11.9265 69.0401 12.7932 67.9709 12.7932H62.5288V18.5584C62.5288 19.6276 61.6621 20.4944 60.5928 20.4944H58.8122Z"
          fill="#245A80"
        />
      </svg>
    </Box>
  )
}
