import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import React from 'react'
import { RiSearchLine } from 'react-icons/ri'
import { useIntl } from 'react-intl'

type Props = {
  search: string
  onSearch: (newValue: string) => void
}

export const SearchInput = ({ search, onSearch }: Props): JSX.Element => {
  const { formatMessage } = useIntl()

  return (
    <InputGroup w="100">
      <InputLeftElement color="gray.500">
        <RiSearchLine />
      </InputLeftElement>
      <Input
        placeholder={formatMessage({ id: 'global.search' })}
        value={search}
        onChange={(e) => onSearch(e.currentTarget.value)}
      />
    </InputGroup>
  )
}
