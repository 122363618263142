import { Button, ButtonGroup, IconButton } from '@chakra-ui/react'
import React from 'react'
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'
import { useIntl } from 'react-intl'

type Props = {
  currentPage: number
  totalPages: number
  onChange: (page: number) => void
  threshold?: number
}

export const Pagination = ({
  currentPage,
  totalPages,
  onChange,
  threshold = 1,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl()
  let leftDots = currentPage - threshold - 1
  if (leftDots <= 1) leftDots = 0

  let rightDots = currentPage + threshold + 1
  if (rightDots >= totalPages) rightDots = Infinity

  return (
    <ButtonGroup isAttached variant="outline" layerStyle="card">
      <IconButton
        mr="-px"
        icon={<RiArrowLeftSLine />}
        aria-label={formatMessage({ id: 'global.previous' })}
        pointerEvents={currentPage === 1 ? 'none' : 'inherit'}
        onClick={() => onChange(Math.max(1, currentPage - 1))}
      />
      {new Array(totalPages).fill('').map((_, i) => {
        const page = i + 1
        const isCurrent = page === currentPage

        const activeProps = {
          zIndex: '1',
          bg: 'pink.100',
          color: 'pink.500',
          borderColor: 'pink.200',
          pointerEvents: 'none',
        } as const

        if (
          page === 1 ||
          page === totalPages ||
          (page > leftDots && page < rightDots)
        ) {
          return (
            <Button
              key={i}
              mr="-px"
              {...(isCurrent ? activeProps : {})}
              onClick={() => onChange(page)}
            >
              {page}
            </Button>
          )
        }

        if (page === leftDots || page === rightDots) {
          return (
            <Button key={i} mr="-px" pointerEvents="none">
              ...
            </Button>
          )
        }

        return null
      })}
      <IconButton
        icon={<RiArrowRightSLine />}
        aria-label={formatMessage({ id: 'global.next' })}
        pointerEvents={currentPage === totalPages ? 'none' : 'inherit'}
        onClick={() => onChange(Math.min(currentPage + 1, totalPages))}
      />
    </ButtonGroup>
  )
}
