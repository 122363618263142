import create from 'zustand'
import { persist } from 'zustand/middleware'

import { Maybe, UserRole } from 'lib/graphql/generated/schemas'

type Clinic = {
  displayName: string
  id: string
  timezone: string
  role?: Maybe<UserRole>
  defaultPatientLanguage?: Maybe<string>
  defaultPatientCountry?: Maybe<string>
}

type PreferencesState = {
  clinic: Clinic | null
  selectedStaff: string | null
}

type PreferencesActions = {
  clear: () => void
  selectClinic: (clinic: Clinic | null) => void
  selectStaff: (id: string | null) => void
}

const initialState: PreferencesState = {
  clinic: null,
  selectedStaff: null,
}

export const usePreferences = create<PreferencesState & PreferencesActions>(
  persist(
    (set) => {
      return {
        ...initialState,
        clear: () => set(initialState),
        selectClinic: (clinic) => {
          set({ clinic, selectedStaff: null })
        },
        selectStaff: (id) => {
          set({ selectedStaff: id })
        },
      }
    },
    {
      name: '___brs_preferences_store___',
    }
  )
)
