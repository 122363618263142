import React, { Fragment } from 'react'

export const orNotAvailable = (value?: string | null): string => value || '—'

export const stringToHash = (text: string): number => {
  let hash = 0

  for (let i = 0; i < text.length; i++) {
    const char = text.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  }

  return hash
}

export const capitalize = (text: string): string => {
  return text
    .toLowerCase()
    .trim()
    .replace(/^\w/, (c) => c.toUpperCase())
}

export const textByLine = (
  string: string,
  renderLine: (line: string, index: number, totalLength: number) => JSX.Element
): JSX.Element[] => {
  const split = string.split('\n')
  return split.map((line, i) => (
    <Fragment key={i}>{renderLine(line, i, split.length)}</Fragment>
  ))
}
