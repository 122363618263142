import {
  Box,
  Button,
  Input,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  Divider,
  useRadio,
  UseRadioProps,
  useRadioGroup,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import { RiNurseFill } from 'react-icons/ri'
import { useIntl } from 'react-intl'

import useDebounce from 'common/utils/useDebounce'
import {
  useGetMeForStaffFilterQuery,
  useGetStaffForFilterQuery,
} from 'lib/graphql/generated/hooks'
import { usePreferences } from 'lib/store/preferences'

const DEFAULT_VALUE = 'anyone'

const _GET_STAFF = /* GraphQL */ `
  query getStaffForFilter($clinicId: UUID4!, $searchTerm: String) {
    clinicUsers(
      clinicId: $clinicId
      searchTerm: $searchTerm
      isStaff: true
      pagination: { page: 1, pageSize: 5 }
    ) {
      totalEntries
      entries {
        user {
          name
          email
          id
        }
      }
    }
  }
`

const _GET_ME = /* GraphQL */ `
  query getMeForStaffFilter {
    me {
      id
    }
  }
`

export const StaffFilter = (): JSX.Element => {
  const { selectedStaff, selectStaff } = usePreferences()
  const { formatMessage } = useIntl()
  const clinic = usePreferences((state) => state.clinic)
  const searchInput = useRef<HTMLInputElement | null>(null)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)

  const { isOpen, onClose, onToggle } = useDisclosure()

  const { data: me } = useGetMeForStaffFilterQuery()
  const { data } = useGetStaffForFilterQuery(
    {
      clinicId: clinic?.id || '',
      searchTerm: debouncedSearch || undefined,
    },
    {
      enabled: !!clinic,
      keepPreviousData: true,
    }
  )

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'staff',
    value: selectedStaff || DEFAULT_VALUE,
    onChange: (value) => {
      selectStaff(value === DEFAULT_VALUE ? null : value)
      onClose()
    },
  })
  const anyoneRadio = getRadioProps({ value: DEFAULT_VALUE })
  const meRadio = getRadioProps({ value: me?.me.id })

  return (
    <>
      <Popover
        placement="bottom-start"
        initialFocusRef={searchInput}
        isOpen={isOpen}
      >
        <PopoverTrigger>
          <Button
            variant="outline"
            color={selectedStaff ? 'red.500' : undefined}
            onClick={onToggle}
          >
            <RiNurseFill />
            {selectedStaff && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '-1',
                  right: '-1',
                  w: '2',
                  h: '2',
                  borderRadius: '100%',
                  backgroundColor: 'red.500',
                }}
              />
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader>
            <Input
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
              ref={searchInput}
              placeholder={formatMessage({ id: 'staffFilter.search' })}
              variant="filled"
            />
          </PopoverHeader>
          <Box {...getRootProps()}>
            <PopoverBody px={0}>
              <RadioButton {...anyoneRadio}>
                {formatMessage({ id: 'staffFilter.anyone' })}
              </RadioButton>
              {me?.me.id && (
                <RadioButton {...meRadio}>
                  {formatMessage({ id: 'staffFilter.me' })}
                </RadioButton>
              )}
            </PopoverBody>
            {data?.clinicUsers.entries && data.clinicUsers.entries.length > 0 && (
              <>
                <Divider />
                <PopoverBody px={0}>
                  {data?.clinicUsers.entries.map((entry) => {
                    const radio = getRadioProps({ value: entry.user.id })

                    return (
                      <RadioButton key={entry.user.id} {...radio}>
                        {entry.user.name}
                      </RadioButton>
                    )
                  })}
                </PopoverBody>
              </>
            )}
          </Box>
          {data?.clinicUsers &&
            data.clinicUsers.totalEntries > data.clinicUsers.entries.length && (
              <PopoverFooter>
                <Text fontSize="xs" color="gray.500">
                  {formatMessage(
                    { id: 'staffFilter.hidden' },
                    {
                      amount:
                        data.clinicUsers.totalEntries -
                        data.clinicUsers.entries.length,
                    }
                  )}
                </Text>
              </PopoverFooter>
            )}
        </PopoverContent>
      </Popover>
    </>
  )
}

const RadioButton: React.FC<UseRadioProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        position="relative"
        cursor="pointer"
        px={4}
        py={2}
        _hover={{
          bg: 'gray.100',
        }}
        pl="10"
        _checked={{
          _before: {
            position: 'absolute',
            left: '4',
            top: '50%',
            transform: 'translateY(-50%)',
            content: '"✓"',
            fontWeight: 'bold',
            color: 'blue.700',
          },
        }}
        _focus={{
          bg: 'gray.100',
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
}
