import {
  Box,
  HStack,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  BoxProps,
  ChakraProps,
} from '@chakra-ui/react'
import { createPopper } from '@popperjs/core'
import { useCombobox, UseComboboxGetInputPropsOptions } from 'downshift'
import React, { useState } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import { RiArrowDownLine, RiCheckLine, RiCloseLine } from 'react-icons/ri'
import { useIntl } from 'react-intl'

type Item = { value: string | number; label: string; subLabel?: string }
type BaseProps = ChakraProps & {
  items: Item[]
  inputProps?: UseComboboxGetInputPropsOptions
  containerProps?: BoxProps
  enableClearButton?: boolean
}
type BaseAutocompleteProps = BaseProps & {
  value: Item | null
  onSelect: (item: Item | null | undefined) => void
}
type RHFAutocompleteProps = BaseProps & {
  controller: UseControllerProps<any>
}

export const Autocomplete = (
  props: BaseAutocompleteProps | RHFAutocompleteProps
): JSX.Element => {
  if ('controller' in props) {
    return <RHFAutocomplete {...props} />
  } else {
    return <BaseAutocomplete {...props} />
  }
}

const RHFAutocomplete = ({
  items,
  controller,
  inputProps,
  ...props
}: RHFAutocompleteProps): JSX.Element => {
  const { field } = useController(controller)
  const { value, onChange, ...restInputProps } = field

  return (
    <BaseAutocomplete
      items={items}
      value={items.find((item) => item.value === value) || null}
      onSelect={(item) => onChange(item?.value || '')}
      inputProps={{ ...restInputProps, ...inputProps }}
      {...props}
    />
  )
}

const BaseAutocomplete = ({
  items,
  value,
  onSelect,
  containerProps,
  inputProps,
  enableClearButton = true,
  ...chakraProps
}: BaseAutocompleteProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const [inputItems, setInputItems] = useState(items)

  const wrapperRef = React.useRef<HTMLInputElement | null>(null)
  const menuRef = React.useRef<HTMLUListElement | null>(null)
  usePopper({ wrapperRef, menuRef })

  const {
    selectedItem,
    reset,
    highlightedIndex,
    isOpen,
    getComboboxProps,
    getInputProps,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useCombobox({
    selectedItem: value,
    items: inputItems,
    itemToString: (item) => item?.label || '',
    onSelectedItemChange: ({ selectedItem }) => onSelect(selectedItem),
    stateReducer: (_state, { changes, type }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur: {
          // auto-select when there's only one suggestion
          if (inputItems.length === 1) {
            return {
              ...changes,
              selectedItem: inputItems[0],
              inputValue: inputItems[0].label,
            }
          }

          const itemMatch = inputItems.find(
            (item) =>
              item.label.toLowerCase() ===
              (changes.inputValue || '').toLowerCase()
          )

          if (itemMatch) {
            return {
              ...changes,
              selectedItem: itemMatch,
              inputValue: itemMatch.label,
            }
          }

          return { ...changes, inputValue: '', selectedItem: null }
        }
        default:
          return changes
      }
    },
    onIsOpenChange: () => {
      // it's nice UX to show all items whenever the panel is opened.
      // the list only gets filtered when typing
      setInputItems(items)
    },
    onInputValueChange: ({ inputValue }) => {
      setInputItems(
        inputValue
          ? items.filter((item) => {
              const itemInLabel = item.label
                .toLowerCase()
                .includes(inputValue.toLowerCase())
              const itemInSublabel =
                item.subLabel &&
                item.subLabel.toLowerCase().includes(inputValue.toLowerCase())
              return itemInLabel || itemInSublabel
            })
          : items
      )
    },
  })

  return (
    <>
      <HStack {...containerProps} {...getComboboxProps({ ref: wrapperRef })}>
        <InputGroup>
          <Input {...chakraProps} {...getInputProps(inputProps)} />
          {selectedItem && enableClearButton && (
            <InputRightElement>
              <IconButton
                size="sm"
                variant="ghost"
                aria-label={formatMessage({ id: 'global.clear' })}
                icon={<RiCloseLine />}
                onClick={reset}
              />
            </InputRightElement>
          )}
        </InputGroup>
        <IconButton
          aria-label={formatMessage({ id: 'global.toggleMenu' })}
          icon={<RiArrowDownLine />}
          {...getToggleButtonProps()}
        />
      </HStack>
      <Box
        layerStyle="card"
        bg="white"
        zIndex="popover"
        {...getMenuProps({ ref: menuRef })}
      >
        {isOpen && (
          <Box as="ul" listStyleType="none" p="2" maxH="56" overflow="auto">
            {inputItems.length === 0 && (
              <Box as="li" borderRadius="sm" p="2" color="gray.400">
                {formatMessage({ id: 'global.nothingFound' })}
              </Box>
            )}
            {inputItems.map((item, i) => {
              return (
                <Box
                  key={item.value}
                  as="li"
                  borderRadius="sm"
                  p={2}
                  bg={highlightedIndex === i ? 'gray.50' : null}
                  color={
                    selectedItem?.value === item.value ? 'blue.400' : 'inherit'
                  }
                  {...getItemProps({
                    item,
                    index: i,
                  })}
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    <Box>
                      <Text>{item.label}</Text>
                      {item.subLabel && (
                        <Text color="gray.400" fontSize="xs">
                          {item.subLabel}
                        </Text>
                      )}
                    </Box>
                    {selectedItem?.value === item.value && <RiCheckLine />}
                  </Flex>
                </Box>
              )
            })}
          </Box>
        )}
      </Box>
    </>
  )
}

const usePopper = ({
  wrapperRef,
  menuRef,
}: {
  wrapperRef: React.MutableRefObject<HTMLInputElement | null>
  menuRef: React.MutableRefObject<HTMLUListElement | null>
}) => {
  React.useEffect(() => {
    if (!wrapperRef.current || !menuRef.current) return

    const popper = createPopper(wrapperRef.current, menuRef.current, {
      placement: 'bottom',
      modifiers: [
        {
          name: 'sameWidth',
          enabled: true,
          phase: 'beforeWrite',
          requires: ['computeStyles'],
          fn: ({ state }) => {
            state.styles.popper.width = `${state.rects.reference.width}px`
          },
          effect: ({ state }) => {
            state.elements.popper.style.width = `${
              state.elements.reference.getBoundingClientRect().width
            }px`

            return () => null
          },
        },
        { name: 'offset', options: { offset: [0, 8] } },
        { name: 'flip', options: { fallbackPlacements: ['top'] } },
      ],
    })

    return () => {
      popper.destroy()
    }
  }, [wrapperRef, menuRef])
}
