import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  IconButton,
  Tooltip,
  useClipboard,
} from '@chakra-ui/react'
import React from 'react'
import {
  RiCheckLine,
  RiClipboardLine,
  RiExternalLinkLine,
} from 'react-icons/ri'
import { useIntl } from 'react-intl'

import { CallAccessRole } from 'lib/graphql/generated/schemas'

export const VideoLink = ({
  type,
  url,
  ...props
}: ButtonGroupProps & {
  type: CallAccessRole
  url?: string | null
}): JSX.Element => {
  const { formatMessage } = useIntl()
  const { hasCopied, onCopy } = useClipboard(url || '')

  const labels: Record<CallAccessRole, string> = {
    HOST: formatMessage({ id: 'appointment.hostLink' }),
    PATIENT: formatMessage({ id: 'appointment.patientLink' }),
  }

  return (
    <ButtonGroup
      isAttached
      variant="outline"
      size="sm"
      display="block"
      isDisabled={!url}
      pointerEvents={!url ? 'none' : 'auto'}
      {...props}
    >
      <Button
        as="span"
        borderRight="0"
        pointerEvents="none"
        bg="gray.100"
        borderTopRightRadius="0"
        borderBottomRightRadius="0"
      >
        {labels[type]}
      </Button>
      <Tooltip
        label={formatMessage({
          id: hasCopied ? 'global.copied' : 'global.copyLink',
        })}
        placement="top"
      >
        <IconButton
          onClick={onCopy}
          borderRight="0"
          borderRadius="0"
          icon={hasCopied ? <RiCheckLine /> : <RiClipboardLine />}
          aria-label={formatMessage({ id: 'global.copyLink' })}
        />
      </Tooltip>
      <Tooltip label={formatMessage({ id: 'global.openLink' })} placement="top">
        <IconButton
          as="a"
          borderTopLeftRadius="0"
          borderBottomLeftRadius="0"
          icon={<RiExternalLinkLine />}
          href={url || ''}
          rel="noopener noreferrer"
          target="_blank"
          aria-label={formatMessage({ id: 'global.openLink' })}
        />
      </Tooltip>
    </ButtonGroup>
  )
}
