import { GraphQLClient } from 'graphql-request'
import { RequestDocument, Variables } from 'graphql-request/dist/types'

import { getPersistedSession } from 'modules/auth/session'

export const GRAPHQL_ENDPOINT = `${process.env.NEXT_PUBLIC_API_URL}/api/v1/graphql`

export const api = <T = any, K = Variables>(
  operation: RequestDocument,
  variables?: K
): Promise<T> => {
  const session = getPersistedSession()

  const client = new GraphQLClient(GRAPHQL_ENDPOINT, {
    headers: {
      'Content-Type': 'application/json',
      authorization: session ? `Bearer ${session.accessToken}` : '',
    },
  })

  return client.request<T, K>(operation, variables)
}

export const createFetcher = <T = any, K = Variables>(
  operation: string,
  variables?: K
): (() => Promise<T>) => () => api(operation, variables)
