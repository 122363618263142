import { Box, BoxProps, keyframes } from '@chakra-ui/react'
import React from 'react'

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

export const BlinkingCircle = (props: BoxProps): JSX.Element => {
  return (
    <Box
      h="2"
      w="2"
      borderRadius="full"
      bg="red.500"
      animation={`${blink} infinite 1s linear alternate`}
      {...props}
    />
  )
}
