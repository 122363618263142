import {
  Button,
  ButtonGroup,
  Text,
  HStack,
  Box,
  Divider,
  IconButton,
  chakra,
  Badge,
  ChakraProps,
} from '@chakra-ui/react'
import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import isToday from 'date-fns/isToday'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import React from 'react'
// eslint-disable-next-line import/order
import {
  Calendar as ReactBigCalendar,
  dateFnsLocalizer,
  HeaderProps,
  ToolbarProps,
  View,
  Event,
  CalendarProps,
} from 'react-big-calendar'

import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'

import { useDateFormat } from 'common/utils/date'
import { capitalize } from 'common/utils/string'

import 'react-big-calendar/lib/css/react-big-calendar.css'

const locales = {
  'en-GB': require('date-fns/locale/en-GB'),
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const ChakraCalendar = chakra(ReactBigCalendar)

type Props = Omit<CalendarProps, 'localizer' | 'events'> &
  ChakraProps & {
    events: {
      title: string
      start: Date
      end: Date
      resource: {
        color?: 'blue' | 'yellow' | 'pink' | 'orange'
        id: string
      }
    }[]
    cta?: {
      label: string
      onClick: () => void
    }
    containerProps?: ChakraProps
    renderInToolbar?: () => JSX.Element
  }

type DateRange = { start: Date; end: Date }

export const Calendar = ({
  events,
  cta,
  containerProps,
  renderInToolbar,
  ...props
}: Props): JSX.Element => {
  const { format } = useDateFormat()

  const timeRangeFormat = (range: DateRange) => {
    return `${format(range.start, 'HH:mm')} → ${format(range.end, 'HH:mm')}`
  }

  const dateRangeFormat = (range: DateRange) => {
    return `${format(range.start, 'PPP')} → ${format(range.end, 'PPP')}`
  }

  const CtaToolbar = cta
    ? (props: ToolbarProps) => (
        <Toolbar {...props} renderInToolbar={renderInToolbar}>
          <Button
            sx={{ flexShrink: 0 }}
            colorScheme="blue"
            onClick={() => cta.onClick()}
          >
            {cta.label}
          </Button>
        </Toolbar>
      )
    : (props: ToolbarProps) => (
        <Toolbar {...props} renderInToolbar={renderInToolbar} />
      )

  return (
    <Box
      layerStyle="card"
      overflow="hidden"
      borderRadius="lg"
      h="50rem"
      {...containerProps}
    >
      <ChakraCalendar
        localizer={localizer}
        formats={{
          agendaHeaderFormat: dateRangeFormat,
          agendaDateFormat: 'dd/MM/yyyy',
          agendaTimeFormat: 'HH:mm',
          agendaTimeRangeFormat: timeRangeFormat,
          dayFormat: (date: Date) => format(date, 'd E'),
          dayHeaderFormat: (date: Date) => format(date, 'PPPP'),
          dayRangeHeaderFormat: dateRangeFormat,
          timeGutterFormat: (date: Date) => format(date, 'HH:mm'),
          eventTimeRangeFormat: timeRangeFormat,
          eventTimeRangeEndFormat: timeRangeFormat,
          eventTimeRangeStartFormat: timeRangeFormat,
          dateFormat: (date: Date) => format(date, 'd'),
          monthHeaderFormat: (date: Date) => format(date, 'LLLL yyyy'),
          selectRangeFormat: timeRangeFormat,
          weekdayFormat: (date: Date) => format(date, 'EEEE'),
        }}
        step={15}
        timeslots={4}
        defaultView="week"
        components={{
          toolbar: CtaToolbar,
          header: Header,
          agenda: {
            date: function DayComponent({
              label,
              day,
            }: {
              label: string
              day: Date
            }) {
              return (
                <Text color={isToday(day) ? 'pink.500' : 'inherit'}>
                  {label}
                </Text>
              )
            },
            event: function EventComponent({
              event,
            }: {
              event: {
                title: string
                resource: { staff: { name?: string; jobTitle?: string } }
              }
            }) {
              return (
                <Box>
                  <Text>{event.title}</Text>
                  {event.resource.staff.name && (
                    <Text color="gray.600">
                      {event.resource.staff.name}
                      {event.resource.staff.jobTitle
                        ? ` - ${event.resource.staff.jobTitle}`
                        : ''}
                    </Text>
                  )}
                </Box>
              )
            },
          },
        }}
        eventPropGetter={(event: Event) => ({
          className: event.resource.color,
        })}
        events={events}
        sx={{
          '.rbc-month-view, .rbc-time-view, .rbc-agenda-view': {
            border: 0,
          },
          '.rbc-month-view *, .rbc-time-view *, .rbc-agenda-view *': {
            borderColor: 'gray.200',
            fontWeight: 'normal',
          },
          // little day number on each cell
          '.rbc-date-cell': {
            textAlign: 'center',
            fontSize: 'small',
          },
          '.rbc-date-cell a': {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            w: '6',
            h: '6',
            m: '1',
            borderRadius: 'full',
          },
          '.rbc-date-cell.rbc-now a': {
            bg: 'pink.500',
            color: 'white',
          },
          // current time
          '.rbc-current-time-indicator': {
            bg: 'orange.500',
          },
          // today background
          '.rbc-today': {
            bg: 'white',
          },
          // prev and next month's cells
          '.rbc-off-range-bg': {
            bg: 'gray.50',
          },
          '.rbc-show-more': {
            px: '2',
            pt: '2',
          },
          // a small horizontal spacing around month's event bars
          '.rbc-row-segment': {
            px: '1',
          },
          // events
          '.rbc-event': {
            fontSize: 'small',
            bg: 'blue.100',
            borderColor: 'blue.200',
            color: 'blue.500',
          },
          '.rbc-event.yellow': {
            bg: 'yellow.100',
            borderColor: 'yellow.500',
            color: 'yellow.700',
          },
          '.rbc-event.pink': {
            bg: 'pink.100',
            borderColor: 'pink.500',
            color: 'pink.700',
          },
          '.rbc-event.orange': {
            bg: 'orange.100',
            borderColor: 'orange.500',
            color: 'orange.700',
          },
          // time slots height
          '.rbc-timeslot-group': {
            minH: '24',
          },
          // timeslots
          '.rbc-time-content': {
            borderTopWidth: '1px',
            borderTopColor: 'gray.200',
          },
          '.rbc-time-header.rbc-overflowing': {
            borderRight: 0,
          },
          '.rbc-time-slot': {
            borderStyle: 'dashed',
          },
          '.rbc-time-slot:first-of-type': {
            border: 0,
          },
          // hours on the left, etc.
          '.rbc-label': {
            fontSize: 'small',
            p: '2',
          },
          // agenda
          'table.rbc-agenda-table': {
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
          },
          '.rbc-agenda-time-cell': {
            borderLeftWidth: '1px',
          },
          '.rbc-agenda-empty': {
            p: '4',
          },
        }}
        {...props}
      />
    </Box>
  )
}

const Toolbar = ({
  localizer,
  onNavigate,
  onView,
  views,
  view,
  label,
  date,
  children,
  renderInToolbar,
}: ToolbarProps & { renderInToolbar?: () => JSX.Element }) => {
  return (
    <>
      {/* Mobile */}
      <Box display={{ base: 'initial', lg: 'none' }}>
        <HStack d="flex" justifyContent="space-between" p="2">
          <HStack>
            <Button onClick={() => onNavigate('TODAY')} variant="outline">
              {localizer.messages.today}
            </Button>
            <IconButton
              size="sm"
              variant="outline"
              borderRadius="full"
              icon={<RiArrowLeftSLine />}
              aria-label={localizer.messages.previous || 'previous'}
              onClick={() => onNavigate('PREV')}
            />
            <IconButton
              size="sm"
              variant="outline"
              borderRadius="full"
              icon={<RiArrowRightSLine />}
              aria-label={localizer.messages.next || 'next'}
              onClick={() => onNavigate('NEXT')}
            />
          </HStack>
          <Text fontSize="md">{label}</Text>
        </HStack>
        <Divider />
      </Box>
      {/* Desktop */}
      <Box display={{ base: 'none', lg: 'initial' }}>
        <HStack d="flex" justifyContent="space-between" p="2">
          <HStack spacing="6">
            <Button onClick={() => onNavigate('TODAY')} variant="outline">
              {localizer.messages.today}
            </Button>
            <HStack>
              <IconButton
                size="sm"
                variant="outline"
                borderRadius="full"
                icon={<RiArrowLeftSLine />}
                aria-label={localizer.messages.previous || 'previous'}
                onClick={() => onNavigate('PREV')}
              />
              <IconButton
                size="sm"
                variant="outline"
                borderRadius="full"
                icon={<RiArrowRightSLine />}
                aria-label={localizer.messages.next || 'next'}
                onClick={() => onNavigate('NEXT')}
              />
              <HStack>
                <Text fontSize="lg">{label}</Text>
                {isToday(new Date(date)) && (
                  <Badge colorScheme="pink">{localizer.messages.today}</Badge>
                )}
              </HStack>
            </HStack>
          </HStack>
          <HStack>
            {typeof renderInToolbar === 'function' && renderInToolbar()}
            <ButtonGroup isAttached variant="outline">
              {(views as View[]).map((v, i) => (
                <Button
                  isDisabled={v === view}
                  _disabled={{ opacity: 1, pointerEvents: 'none' }}
                  bg={v === view ? 'gray.100' : 'inherit'}
                  key={v}
                  onClick={() => onView(v)}
                  borderLeftWidth={i === 0 ? '1px' : 0}
                >
                  {capitalize(v)}
                </Button>
              ))}
            </ButtonGroup>
            {children}
          </HStack>
        </HStack>
        <Divider />
      </Box>
    </>
  )
}

const Header = ({ label }: HeaderProps) => {
  return (
    <Box as="span" role="columnheader" aria-sort="none" fontWeight="normal">
      <Text>{label}</Text>
    </Box>
  )
}
