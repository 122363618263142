import { Box, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { useIntl } from 'react-intl'

export const NoResults = (): JSX.Element => {
  const { formatMessage } = useIntl()
  return (
    <Box textAlign="center" mt="8">
      <Heading size="md" mb="2">
        {formatMessage({ id: 'global.noResults' })}
      </Heading>
      <Text>{formatMessage({ id: 'global.noResults.description' })}</Text>
    </Box>
  )
}
