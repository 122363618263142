import {
  Badge,
  Button,
  Checkbox,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { RiArrowDownSLine } from 'react-icons/ri'

import { SearchInput } from './SearchInput'

type Action<T = any> = {
  label: string
  value: T[]
  onChange: (newValue: T) => void
  options: { label: string; value: T }[]
}

type Props = {
  isLoading?: boolean
  search?: string
  onSearch?: (search: string) => void
  cta?: {
    label: string
    onClick: () => void
  }
  actions?: Action[]
}

export const TableHeader: React.FC<Props> = ({
  isLoading,
  search,
  onSearch,
  cta,
  actions,
  children,
}): JSX.Element | null => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  // Unfortunately, Skeleton isn't working very well on SSR, so we're
  // making this component client-side only.
  if (!mounted) return null

  return (
    <>
      <HStack justifyContent="space-between" mb="4">
        {typeof search === 'string' && typeof onSearch === 'function' ? (
          <Skeleton isLoaded={!isLoading}>
            <SearchInput search={search} onSearch={onSearch} />
          </Skeleton>
        ) : (
          <div />
        )}
        <Skeleton isLoaded={!isLoading}>
          <HStack>
            {children}
            {(actions || []).map((action, i) => {
              return (
                <Menu
                  key={i}
                  placement="bottom-end"
                  autoSelect={false}
                  closeOnSelect={false}
                >
                  <MenuButton
                    as={Button}
                    rightIcon={<RiArrowDownSLine />}
                    variant="outline"
                  >
                    <HStack>
                      {action.value.length > 0 && (
                        <Badge colorScheme="blue">{action.value.length}</Badge>
                      )}
                      <Text isTruncated maxWidth="40">
                        {action.label}
                      </Text>
                    </HStack>
                  </MenuButton>
                  <MenuList>
                    {action.options.map((option, ii) => {
                      return (
                        <MenuItem
                          key={ii}
                          onClick={() => action.onChange(option.value)}
                        >
                          <HStack>
                            <Checkbox
                              pointerEvents="none"
                              isChecked={action.value.includes(option.value)}
                            />
                            <span>{option.label}</span>
                          </HStack>
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                </Menu>
              )
            })}
            {cta && (
              <Button variant="solid" colorScheme="blue" onClick={cta.onClick}>
                {cta.label}
              </Button>
            )}
          </HStack>
        </Skeleton>
      </HStack>
      {isLoading && <Skeleton h="12" />}
    </>
  )
}
