import { Box, Divider, HStack, Skeleton, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

export const TableLoader = (): JSX.Element | null => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  // Unfortunately, Skeleton isn't working very well on SSR, so we're
  // making this component client-side only.
  if (!mounted) return null

  return (
    <Box layerStyle="card">
      <Box p="2">
        <HStack spacing="8">
          {new Array(6).fill('').map((_, i) => (
            <Skeleton key={i} h="4" w="100%" />
          ))}
        </HStack>
      </Box>
      <Divider />
      <Stack p="2">
        {new Array(5).fill('').map((_, i) => (
          <Skeleton key={i} h="10" />
        ))}
      </Stack>
    </Box>
  )
}
