import { Locale } from 'date-fns'
import dateFnsFormatTimezone from 'date-fns-tz/format'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'
import dateFnsFormat from 'date-fns/format'
import { enGB } from 'date-fns/locale'
import { useRouter } from 'next/dist/client/router'

const locales: Record<string, Locale> = {
  en: enGB,
}

const defaultFormat = 'PP'

type Format = (date: Date, format?: string, locale?: Locale) => string
type FormatTz = (
  date: Date,
  timezone: string,
  format?: string,
  locale?: Locale
) => string

export const format: Format = (
  date,
  dateStr = defaultFormat,
  locale = locales['en']
) => {
  return dateFnsFormat(date, dateStr, { locale })
}

export const formatTz: FormatTz = (
  utcDate,
  timezone,
  dateStr = defaultFormat,
  locale = locales['en']
) => {
  return dateFnsFormatTimezone(utcToZonedTime(utcDate, timezone), dateStr, {
    locale,
    timeZone: timezone,
  })
}

type LocalizedFormat = (date: Date, format?: string) => string
type LocalizedFormatTz = (
  date: Date,
  timezone: string,
  format?: string
) => string
export const useDateFormat = (): {
  format: LocalizedFormat
  formatTz: LocalizedFormatTz
} => {
  const { locale, defaultLocale } = useRouter()

  return {
    format: (date, dateStr = defaultFormat) => {
      return format(date, dateStr, locales[locale || defaultLocale || 'en'])
    },
    formatTz: (utcDate, timezone, dateStr = defaultFormat) => {
      return formatTz(
        utcDate,
        timezone,
        dateStr,
        locales[locale || defaultLocale || 'en']
      )
    },
  }
}

export const getIsoDate = (
  date?: string,
  time?: string,
  timezone?: string
): string | null => {
  const [yyyy, mm, dd] = (date || '').split('-')
  const [hour, minutes] = (time || '').split(':')

  return dd && mm && yyyy && hour && minutes && timezone
    ? zonedTimeToUtc(
        new Date(`${mm}/${dd}/${yyyy} ${hour}:${minutes}`),
        timezone
      ).toISOString()
    : null
}
