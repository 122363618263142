import { Flex, Heading, Text, FlexProps } from '@chakra-ui/react'
import React from 'react'
import { RiEmotionSadLine } from 'react-icons/ri'
import { useIntl } from 'react-intl'

export const SomethingWentWrong = (
  props: FlexProps & { title?: string; message?: string }
): JSX.Element => {
  const { formatMessage } = useIntl()
  const title =
    props.title || formatMessage({ id: 'global.somethingWentWrong' })
  const message =
    props.message || formatMessage({ id: 'global.tryAgainOrContactSupport' })

  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Text fontSize="x-large">
        <RiEmotionSadLine />
      </Text>
      <Heading size="md" my="2">
        {title}
      </Heading>
      <Text>{message}</Text>
    </Flex>
  )
}
