import { Text, Tag } from '@chakra-ui/react'
import React from 'react'
import { useIntl } from 'react-intl'

import { useGetCallStatusQuery } from 'lib/graphql/generated/hooks'
import { Appointment } from 'lib/graphql/generated/schemas'

import { BlinkingCircle } from './BlinkingCircle'

type Props = {
  appointmentId: Appointment['id'] | null
}

const _GET_CALL_STATUS = /* GraphQL */ `
  query getCallStatus($id: UUID4!) {
    callStatus(appointmentId: $id) {
      clientCount
    }
  }
`

export const CallStatus = ({ appointmentId }: Props): JSX.Element | null => {
  const { formatMessage } = useIntl()

  const { data, isLoading, isError } = useGetCallStatusQuery(
    {
      id: appointmentId,
    },
    {
      refetchInterval: 1000,
    }
  )

  const clientCount = data?.callStatus.clientCount || 0

  if (isError || isLoading || clientCount === 0) return null

  return (
    <Tag mt="4" size="md" variant="subtle" colorScheme="red">
      <BlinkingCircle mr="2" />
      <Text color="red.500">
        {clientCount === 1
          ? formatMessage({ id: 'appointment.callStatus.one' })
          : formatMessage(
              { id: 'appointment.callStatus.many' },
              { count: clientCount }
            )}
      </Text>
    </Tag>
  )
}
