import {
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react'
import React, { useRef } from 'react'
import { useIntl } from 'react-intl'

type Props = Omit<AlertDialogProps, 'children' | 'leastDestructiveRef'> & {
  onConfirm: () => void
  isLoading?: boolean
  isError?: boolean
  errorMessage?: string
  title: string
  text: string
  cancel?: string
  confirm?: string
}

export const ConfirmDialog = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  isError,
  errorMessage,
  title,
  text,
  cancel,
  confirm,
  colorScheme = 'red',
  ...props
}: Props): JSX.Element => {
  const cancelRef = useRef<HTMLButtonElement | null>(null)
  const { formatMessage } = useIntl()
  const errorString =
    errorMessage ||
    `${formatMessage({ id: 'global.somethingWentWrong' })} ${formatMessage({
      id: 'global.tryAgainOrContactSupport',
    })}`

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      {...props}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>

          <AlertDialogBody>
            {text}
            {isError && (
              <>
                <Text color="red.500" mt="4">
                  {errorString}
                </Text>
              </>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} ref={cancelRef}>
              {cancel || formatMessage({ id: 'global.cancel' })}
            </Button>
            <Button
              colorScheme={colorScheme}
              ml={3}
              isLoading={isLoading}
              onClick={onConfirm}
            >
              {confirm || formatMessage({ id: 'global.confirm' })}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
